import React, { useRef, useState, useContext } from 'react';

import { MainContext } from '../context/mainContext';

import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';

import { useForm } from '../hooks/useForm';

import { Alert } from './Alert';
import { types } from '../types';

export const FormClave = () => {
  const {
    state: { data },
    dispatch,
  } = useContext(MainContext);

  const captcha = useRef(null);
  const history = useHistory();

  const [{ clave }, handleInputChange, reset] = useForm({
    clave: '',
  });

  const [error, setError] = useState({
    disabled: true,
    messageError: '',
    idAlert: '',
    urlValid: false,
  });

  const [isCreated, setIsCreated] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [toBlockValidatePin, setToBlockValidatePin] = useState(false);
  const [reCaptcha, setReCapcha] = useState(false);

  // const { disabled } = error;

  // reCapcha marcado
  const handleOnChangeReCaptcha = () => {
    if (captcha.current.getValue()) {
      setReCapcha(true);
    }
  };

  // la validación del reCapcha ha caducado
  const handleOnExpiredReCaptcha = () => {
    setReCapcha(false);
  }

  // Inicio de 'Validación PIN', acceso al portal
  const handleSubmit = (evt) => {
    evt.preventDefault();

    if(!reCaptcha) {
      setError({
        ...error,
        urlValid: false,
        idAlert: 'msjError',
        messageError: 'Por favor confirme que no es un robot marcando la casilla del reCapcha',
      });
      return;
    }

    setToBlockValidatePin(true);
    console.log('Validación PIN');

    const promise = validatePin();

    promise.then((resp) => {

      if (resp === undefined) {
        setError({
          ...error,
          urlValid: false,
          idAlert: 'msjError',
          messageError: 'El servicio no está disponible, intente más tarde',
        });
        setShowForm(false);
        return;
      }

      setToBlockValidatePin(false);

      // Token no encontrado
      if (resp.status === 404) {
        setError({
          ...error,
          urlValid: false,
          idAlert: 'msjError',
          messageError: 'Token no válido',
        });
        reset();
      }

      switch (resp.codeHttp) {
        case 200: // Acceso al portal 'Pin valido'
          dispatch({
            type: types.setAccounts,
            payload: {
              accounts: resp.listAccounts.accounts,
              pin: clave,
            },
          });

          reset();
          history.push('/valida-password');
          break;
        case 400: // No acceso al portal 'PIN erroneo mostrar intentos fallidos'
          setError({
            ...error,
            urlValid: resp.status,
            idAlert: 'msjError',
            messageError: `La clave es incorrecta. Inténtalo nuevamente. ${resp.message}`,
          });
          break;
        case 409:
          setError({
            // No acceso al portal 'El token puede ser renovado'
            ...error,
            urlValid: resp.status,
            idAlert: 'msjWarning',
            messageError: resp.message,
          });

          setShowForm(false);

          setTimeout(() => {
            const button = document.createElement('button');
            button.classList.add('btn');
            button.style.display = 'block';
            button.style.margin = '10px auto';
            button.textContent = 'Renovar';

            button.addEventListener('click', () => {
              console.log('Renovar URL');
              button.disabled = true;
              renewUrl()
                .then((resp) => {
                  button.disabled = false;

                  // El backend no responde
                  if (resp === undefined) {
                    setError({
                      ...error,
                      urlValid: false,
                      idAlert: 'msjError',
                      messageError: 'El servicio no está disponible, intente más tarde',
                    });
                    setShowForm(false);
                    return;
                  }

                  if (resp.codeHttp === 200) {
                    setError({
                      disabled: true,
                      messageError: '',
                      idAlert: '',
                      urlValid: false,
                    });

                    reset();

                    setError({
                      ...error,
                      urlValid: resp.status,
                      idAlert: 'msj',
                      messageError: resp.message,
                    });
                  }

                  if (resp.codeHttp === 500) {
                    // Error en el servidor
                    setError({
                      ...error,
                      urlValid: false,
                      idAlert: 'msjError',
                      messageError: 'Algo salio mal, intente más tarde',
                    });
                    reset();
                  }

                  if (resp.status === 404) {
                    setError({
                      ...error,
                      urlValid: false,
                      idAlert: 'msjError',
                      messageError: 'Token no válido',
                    });
                    reset();
                  }
                })
                .catch(() => alert('No se puede renovar'));
            });

            const alert = document.getElementById('msjWarning');

            if (alert) {
              if (!isCreated) {
                setIsCreated(true);
                alert.appendChild(button);
              } else {
                return;
              }
            }
          }, 500);
          break;
        case 406:
          setError({
            ...error,
            urlValid: resp.status,
            idAlert: 'msj',
            messageError: resp.message,
          });

          setShowForm(false);
          reset();
          break;
        case 429:
          setError({
            ...error,
            urlValid: resp.status,
            idAlert: 'msjError',
            messageError: resp.message,
          });

          reset();
          break;
        case 500:
          // Error en el servidor
          setError({
            ...error,
            urlValid: false,
            idAlert: 'msjError',
            messageError: 'Algo salio mal, intente más tarde',
          });
          reset();
          break;
        default:
          break;
      }
    });
  };

  // Validar PIN, acceso al Portal
  const validatePin = async () => {
    const body = {
      id: data.id,
      pin: clave,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/ChangePassword/validate_pin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: JSON.stringify(body),
          mode: 'cors',
        }
      );

      return await response.json();
    } catch (error) {
      console.log('Error service', error);
    }
  };

  // Renovar URL
  const renewUrl = async () => {
    const body = {
      id: data.id,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/ChangePassword/renew_url`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: JSON.stringify(body),
          mode: 'cors',
        }
      );

      return await response.json();
    } catch (error) {
      console.log('Error service', error);
    }
  };

  return (
    <>
      {!error.urlValid && (
        <Alert message={error.messageError} id={error.idAlert} />
      )}

      {showForm && (
        <form onSubmit={handleSubmit}>
          <p>
            PIN:{' '}
            <input
              className="inputEnviar"
              type="text"
              autoComplete="off"
              name="clave"
              value={clave}
              onChange={handleInputChange}
              maxLength="8"
            />
          </p>

          <div className="reCaptcha">
            <ReCAPTCHA
              ref={captcha}
              sitekey={process.env.REACT_APP_KEY_RECAPTCHA}
              onChange={handleOnChangeReCaptcha}
              onExpired={handleOnExpiredReCaptcha}
            />
          </div>

          <input className="btn" type="submit" disabled={toBlockValidatePin} />
        </form>
      )}
    </>
  );
};
