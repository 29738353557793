import { DataTable } from '../components/DataTable';
import { FormHome } from '../components/FormHome';
import { Help } from '../components/Help';

export const ValidPassword = () => {
  return (
    <div className="animate__animated animate__fadeIn">
      <div className="div-second">
        <h1>&nbsp;</h1>
        <h1>
          Asigna tu contrase&ntilde;a del Estado de Cuenta Electr&oacute;nico
        </h1>
        <br />
        <p>Contratos a los que se aplicar&aacute; el cambio:</p>

        <DataTable />

        <h2>Consideraciones para definir contrase&ntilde;a:</h2>
        <ul>
          <li>M&iacute;nimo 8 caracteres y m&aacute;ximo 18 caracteres</li>
          <li>Al menos una letra May&uacute;scula</li>
          <li>Al menos un caracter especial (@, %, &amp;, #...)</li>
        </ul>

        <FormHome />

        <Help />
      </div>
    </div>
  );
};
