export const Logotipo = () => {
  return (
    <div className="first-div">
      <a href="https://invex.com" target="_blank" rel="noreferrer">
        <img
          className="logo"
          src="https://invex.com/INVEX/media/invex-images/invex-logo-calado.svg?ext=.svg"
          alt="logo"
        />
      </a>
    </div>
  );
};
