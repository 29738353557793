import { types } from '../types';

export const mainReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getAll:
      return {
        ...action.payload
      };
    case types.setAccounts:
      return {
        ...state,
        accounts: action.payload
      }
    case types.clearData:
      return {}
    default:
      return state;
  }
};
