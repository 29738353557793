import React from 'react';

export const Help = () => {
  return (
    <p>
      Si tienes alguna duda comun&iacute;cate con tu banquero o al (55) 5350 33
      33, ser&aacute; un gusto atenderte.
    </p>
  );
};
