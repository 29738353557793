import React, { useReducer } from 'react';

import { Logotipo } from './components/Logotipo';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { AppRouter } from './routes/AppRouter';

import { mainReducer } from './context/mainReducer';
import { MainContext } from './context/mainContext';

export const App = () => {
  const [state, dispatch] = useReducer(mainReducer, {});

  return (
    <MainContext.Provider
      value={{
        dispatch,
        state
      }}
    >
      <Logotipo />
      <Header />

      <AppRouter />

      <Footer />
    </MainContext.Provider>
  );
};
