import { useContext } from 'react';

import { MainContext } from '../context/mainContext';

export const DataTable = () => {
  const { state } = useContext(MainContext);

  return (
    <>
      <table className="tablaDatos">
        <tbody>
          <tr>
            <th>Contrato</th>
            <th>Producto</th>
            <th>Nombre del Titular</th>
            <th>Correo de Notificaci&oacute;n</th>
          </tr>

          {
            state.accounts.accounts.map((account) => (
              <tr key={ account.account}>
                <td>{account.account}</td>
                <td>{account.product}</td>
                <td>{account.name}</td>
                <td>{account.email}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <br />
      <hr />
    </>
  );
};
