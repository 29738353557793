import { useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { MainContext } from '../context/mainContext';

import { useForm } from '../hooks/useForm';
import { types } from '../types';

import { Alert } from './Alert';

export const FormHome = () => {
  const history = useHistory();
  const {
    state: { data, accounts },
    dispatch,
  } = useContext(MainContext);

  const [{ password, confirmPassword }, handleInputChange, reset] = useForm({
    password: '',
    confirmPassword: '',
  });

  const [errorState, setErrorState] = useState({
    error: false,
    messageError: '',
    idAlert: '',
  });

  const [showForm, setShowForm] = useState(true);
  const [toBlockSendPassword, setToBlockSendPassword] = useState(false);
  const [isCreated, setIsCreated] = useState(false);

  const { error, messageError, idAlert } = errorState;

  const regexCapitalLetter = new RegExp(/[A-Z]/);
  const regexCharEspecial = new RegExp(/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);

  const handleExit = () => {
    dispatch({
      type: types.clearData,
    });

    history.replace('/');
  };

  const handleSubmit = async (evt) => {
    console.log('Envio de contraseña');
    evt.preventDefault();

    if (
      password.length < 8 ||
      !regexCapitalLetter.test(password) ||
      !regexCharEspecial.test(password)
    ) {
      setErrorState({
        ...errorState,
        error: true,
        messageError:
          'La contraseña no cumple con las recomendaciones de seguridad, por favor inténtalo de nuevo.',
        idAlert: 'msjWarning',
      });

      return;
    } else {
      setErrorState({
        ...errorState,
        error: false,
      });
    }

    if (password !== confirmPassword) {
      setErrorState({
        ...errorState,
        error: true,
        messageError:
          'La contraseña no coincide, por favor inténtalo de nuevo.',
        idAlert: 'msjError',
      });

      return;
    } else {
      setErrorState({
        ...errorState,
        error: false,
      });
    }

    const body = {
      id: data.id,
      pin: accounts.pin,
      password,
    };

    setToBlockSendPassword(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/ChangePassword/send_password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: JSON.stringify(body),
          mode: 'cors',
        }
      );

      const data = await response.json();
      setToBlockSendPassword(false);

      // El backend no responde
      if (data === undefined) {
        setErrorState({
          ...errorState,
          error: true,
          messageError: 'El servicio no está disponible, intente más tarde',
          idAlert: 'msjError',
        });

        setShowForm(false);
        return;
      }

      // Contraseña modificada
      if (data.status === true) {
        setErrorState({
          ...errorState,
          error: true,
          messageError:
            'La asignación de contraseña al Estado de Cuenta fue exitosa.',
          idAlert: 'msjApproved',
        });

        setShowForm(false);

        reset();
      }

      // Url expirada, no se puede renovar
      if (data.codeHttp === 406) {
        setErrorState({
          ...errorState,
          error: true,
          messageError: data.message,
          idAlert: 'msj',
        });

        setShowForm(false);
      }

      // Url expirada, se puede renovar
      if (data.codeHttp === 409) {
        setErrorState({
          ...errorState,
          error: true,
          messageError: data.message,
          idAlert: 'msjError',
        });

        setShowForm(false);

        setTimeout(() => {
          const button = document.createElement('button');
          button.classList.add('btn');
          button.style.display = 'block';
          button.style.margin = '10px auto';
          button.textContent = 'Renovar';

          button.addEventListener('click', () => {
            button.disabled = true;
            console.log('Renew URL desde home');
            renewUrl()
              .then((resp) => {
                button.disabled = false;

                // El backend no responde
                if (resp === undefined) {
                  setErrorState({
                    ...errorState,
                    error: true,
                    messageError: 'El servicio no está disponible, intente más tarde',
                    idAlert: 'msjError',
                  });

                  setShowForm(false);
                  return;
                }

                // Url renovada
                if (resp.codeHttp === 200) {
                  setErrorState({
                    error: false,
                    messageError: '',
                    idAlert: '',
                  });

                  reset();

                  setErrorState({
                    ...errorState,
                    error: true,
                    messageError: resp.message,
                    idAlert: 'msj',
                  });
                }

                // Url no renovada, token invalido
                if (resp.status === 404) {
                  reset();

                  setErrorState({
                    ...errorState,
                    error: true,
                    messageError: 'Token no válido',
                    idAlert: 'msjError',
                  });
                }

                // Algo salio mal en el backend
                if (resp.codeHttp === 500) {
                  setErrorState({
                    ...errorState,
                    error: true,
                    messageError:
                      'El servicio no está disponible, intente más tarde',
                    idAlert: 'msjError',
                  });
                }
              })
              .catch(() => alert('No se puede renovar'));
          });

          const alert = document.getElementById('msjError');

          if (alert) {
            if (!isCreated) {
              setIsCreated(true);
              alert.appendChild(button);
            } else {
              return;
            }
          }
        }, 500);
      }

      // La contraseña no cumple con las caracteristicas de seguridad del servidor
      if (data.status === 400) {
        setErrorState({
          ...errorState,
          error: true,
          messageError:
            'La contraseña no cumple con las recomendaciones de seguridad, por favor inténtalo de nuevo.',
          idAlert: 'msjWarning',
        });
      }

      // Algo salio mal en el backend
      if (data.codeHttp === 500) {
        setErrorState({
          ...errorState,
          error: true,
          messageError: 'El servicio no está disponible, intente más tarde',
          idAlert: 'msjError',
        });
      }
    } catch (error) {
      console.log('Error service', error);
    }
  };

  const renewUrl = async () => {
    const body = {
      id: data.id,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/ChangePassword/renew_url`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: JSON.stringify(body),
          mode: 'cors',
        }
      );

      return await response.json();
    } catch (error) {
      console.log('Error service', error);
    }
  };

  return (
    <>
      {error && <Alert message={messageError} id={idAlert} />}

      {showForm && (
        <form onSubmit={handleSubmit}>
          <div id="contrasena">
            <p>
              Contrase&ntilde;a:{' '}
              <input
                className="inputContrasena"
                type="password"
                name="password"
                autoComplete="off"
                onChange={handleInputChange}
                value={password}
              />
            </p>

            <p>
              Confirmar contrase&ntilde;a:{' '}
              <input
                className="inputContrasena"
                type="password"
                name="confirmPassword"
                autoComplete="off"
                onChange={handleInputChange}
                value={confirmPassword}
              />
            </p>

            <p className="text-r">
              <input
                disabled={toBlockSendPassword}
                className="btn"
                type="submit"
                value="Asignar contrase&ntilde;a"
              />
            </p>
          </div>

          <hr />
          <br />

          <p>
            La contrase&ntilde;a asignada a los contratos,{' '}
            <strong>
              ser&aacute; efectiva a partir del siguiente Estado de Cuenta
            </strong>
            .
          </p>
        </form>
      )}

      <br />
      <br />
      <input className="btn" type="button" value="Salir" onClick={handleExit} />
      <br />
      <br />
    </>
  );
};
