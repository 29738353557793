import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { ValidPassword } from '../pages/ValidPassword';
import { Clave } from '../pages/Clave';

export const AppRouter = () => {
  return (
    <>
      <Router>
        <div>
          <Switch>
            <Route exact path="/home/:urlId" component={Clave} />
            <Route exact path="/valida-password" component={ValidPassword} />
            <Redirect to="/home/edc-cifrado-invex" />
          </Switch>
        </div>
      </Router>
    </>
  );
};
