export const Header = () => {
  return (
    <div id="headerContainer">
      <div id="iContainer">
        <div id="i2"></div>
        <div id="i"></div>
      </div>
      <img
        src="https://invex.com/getmedia/e35d5f3e-82aa-4cf2-8692-bbd82496b524/header-3"
        width="100%"
        className="mt-2"
        alt="header"
      />
    </div>
  );
};
